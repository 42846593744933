<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 120 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title text-truncate"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              touch_app
            </v-icon>

            <span class="text-truncate">
              Engagements
            </span>
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                volume_down
              </v-icon>

              Engagements
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Identify influencers by number of their engagements.
              </span>
            </v-tooltip>
          </div>

          <template v-if="!onlyRateInput">
            <engagement-selector
              class="mt-3"
              v-model="form.from"
              label="From"
              @change="submitForm"
              :max-value="form.to"
            />

            <engagement-selector
              class="mt-3"
              v-model="form.to"
              label="To"
              @change="submitForm"
              :min-value="form.from"
            />

            <div class="mb-1 mt-4 d-flex">
              <div class="d-flex align-center flex-grow-1">
                <v-icon
                  :class="{ 'black--text': isThemeLight }"
                  class="mr-1"
                  left
                >
                  volume_down
                </v-icon>

                Engagements Rate
              </div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="isThemeLight ? 'black' : null"
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>

                <span>
                  Identify influencers by their engagement rate. ER is average likes of their recent posts divided by their followers.
                </span>
              </v-tooltip>
            </div>
          </template>

          <engagement-rate-selector
            class="mt-3"
            v-model="form.rate"
            label="Percentage"
            @change="submitForm"
            :include-decimal-values="includeDecimalValues"
          />
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
const EngagementSelector = () => import(/* webpackChunkName: "engagement-selector" */ "@/blocks/common/selectors/EngagementSelector.vue")
const EngagementRateSelector = () => import(/* webpackChunkName: "engagement-rate-selector" */ "@/blocks/common/selectors/EngagementRateSelector.vue")

// used throughout the code
const filterName = "engagements"

const minValue = "1000"
const maxValue = "1000000"

const originalForm = () => ({
  from: minValue,
  to: maxValue,
  rate: 0.01
})

export default {
  name: "FilterEngagements",

  components: {
    EngagementSelector,
    EngagementRateSelector
  },

  props: {
    moduleName: {
      type: String,
      required: true
    },

    isFilterApplied: {
      type: Boolean,
      required: true
    },

    onlyRateInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    includeDecimalValues: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },
  },

  validations: {
    form: {}
  },

  methods: {
    /**
     * Takes an item and generates the text
     */
    computeFilterText(showEngagement) {
      return 'Engagement: '
        + (showEngagement ? `${nFormatter(this.form.from)}-${nFormatter(this.form.to)} | ` : '')
        + `≥${this.form.rate * 100}%`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm() {
      await this.$v.form.$touch()

      if (this.$v.form.$anyError) return

      let showEngagement = true
      if (this.form.from === minValue && this.form.to === maxValue) {
        showEngagement = false
      }

      // if there's some value, then update the filter
      this.$emit("submit", {
        type: filterName,
        data: {
          color: "deep-orange lighten-5",
          icon: "touch_app",
          iconColor: "deep-orange",
          text: this.computeFilterText(showEngagement),
          inputs: {
            from: showEngagement ? this.form.from : '',
            to: showEngagement ? this.form.to : '',
            rate: this.form.rate
          }
        }
      })
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== this.moduleName || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== this.moduleName || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      const updatedValues = e.detail.item.data.inputs

      this.form = {
        from: updatedValues.from || minValue,
        to: updatedValues.to || maxValue,
        rate: updatedValues.rate
      }
    })
  }
}
</script>
