<template>
  <filter-engagements-block
    module-name="influencerDiscovery"
    :only-rate-input="false"
    :is-filter-applied="isFilterApplied"
    :include-decimal-values="platform === 'youtube'"
    @submit="handleSubmit"
  />
</template>

<script>
const FilterEngagementsBlock = () => import(/* webpackChunkName: "filter-engagements" */ "@/blocks/common/filters/FilterEngagements.vue")

export default {
  name: "FilterEngagements",

  components: {
    FilterEngagementsBlock
  },

  props: {
    platform: {
      type: String,
      required: true
    }
  },

  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters[`influencerDiscovery/findFilterByType`]("engagements"))
    }
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch(`influencerDiscovery/replaceFilter`, data)
    }
  }
}
</script>
