var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.computedComponentName,{tag:"component",attrs:{"close-on-content-click":false,"min-width":_vm.computedComponentName === 'v-menu' ? 120 : null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-between filter-title text-truncate",class:{ 'font-weight-bold': _vm.isFilterApplied }},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" touch_app ")]),_c('span',{staticClass:"text-truncate"},[_vm._v(" Engagements ")])],1),_c('v-icon',[_vm._v(" expand_more ")])],1)]}}]),model:{value:(_vm.shouldShowModal),callback:function ($$v) {_vm.shouldShowModal=$$v},expression:"shouldShowModal"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-md-3",class:{ 'black--text': _vm.isThemeLight }},[_c('div',{staticClass:"mb-1 d-flex"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-icon',{staticClass:"mr-1",class:{ 'black--text': _vm.isThemeLight },attrs:{"left":""}},[_vm._v(" volume_down ")]),_vm._v(" Engagements ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isThemeLight ? 'black' : null}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Identify influencers by number of their engagements. ")])])],1),(!_vm.onlyRateInput)?[_c('engagement-selector',{staticClass:"mt-3",attrs:{"label":"From","max-value":_vm.form.to},on:{"change":_vm.submitForm},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}}),_c('engagement-selector',{staticClass:"mt-3",attrs:{"label":"To","min-value":_vm.form.from},on:{"change":_vm.submitForm},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}),_c('div',{staticClass:"mb-1 mt-4 d-flex"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-icon',{staticClass:"mr-1",class:{ 'black--text': _vm.isThemeLight },attrs:{"left":""}},[_vm._v(" volume_down ")]),_vm._v(" Engagements Rate ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isThemeLight ? 'black' : null}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}],null,false,569376422)},[_c('span',[_vm._v(" Identify influencers by their engagement rate. ER is average likes of their recent posts divided by their followers. ")])])],1)]:_vm._e(),_c('engagement-rate-selector',{staticClass:"mt-3",attrs:{"label":"Percentage","include-decimal-values":_vm.includeDecimalValues},on:{"change":_vm.submitForm},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }